import styleConfig from '@/styles/style-config'

import { useEffect, useState, useRef } from 'react'
import dynamic from 'next/dynamic'
import { getProductPopUpPosition } from '@/lib/searchspring'
import { useRouter } from 'next/router'
import { default as pagePaths } from '../../public/promotion-urls.json'
import useIsInViewport from '@/hooks/useIntersection'
import {viewContentPromotionEvent} from '@/lib/ga-events'
import { getContentModel } from '@/lib/helper'

const Icon = dynamic(() => import('@/components/Icon/Icon').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})

const ProductPopUpCard = dynamic(
  () => import('@/components/Product/ProductPopUpCard').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
const ProductPopUp = ({ showProductMenu, setShowProductMenu, products, heroType, moduleProps }) => {
  const [success, setSuccess] = useState(false);
  const router = useRouter()
  const ref = useRef(null)
  const inViewport = useIsInViewport(ref)
  let pageModel = getContentModel('page')
  let heroModel = getContentModel('moduleHero')

  useEffect(() => {
    if(inViewport) {
      if (success) return;
      const sucessFunction = async () => {
        try {
          // console.log(moduleProps)
          let currentPath = router.asPath;
          let promotionId = (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL)+currentPath;
          promotionId = promotionId.startsWith('http') ? promotionId : 'https://'+promotionId

          //PROMOTION NAME & CREATIVE NAME & CREATIVE SLOT & locationId
          let promotionName
          let creativeName
          let creativeSlot
          let locationId
          if(currentPath == '/') {
            promotionName = pageModel.name
          } else {
            let pageType = pagePaths.find(page => page.path == currentPath)
            promotionName = pageType?.type ? getContentModel(pageType?.type)?.name : pageModel.name
            // promotionName = promotionName+' :: Module | Hero Section'
          }
          let allowTrigger = false
          if(moduleProps) {
            let contentType = moduleProps?.sys?.contentType?.sys?.id || moduleProps?.contentType
            
            creativeName = moduleProps?.entryTitle || moduleProps?.fields?.entryTitle || moduleProps?.textAndImage || moduleProps?.sectionName
            locationId = moduleProps?.index ? moduleProps?.index + 1 : 1
            creativeSlot = `Modules::${locationId}`
            if(moduleProps?.categoryLevel) {
              locationId = 'Navigation::'+moduleProps?.categoryLevel
              creativeSlot = 'Marketing Content'
            }
            if(contentType) {
              let contentModel = getContentModel(contentType)
              switch (contentType) {
                case 'moduleHero':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleNavigationContentBlock':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}::${heroModel.name}`
                  break;
                case 'moduleHeroSection':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleContent2Images':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleContent2Blocks':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'productFeatures':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleContent3Blocks':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleGlobalBrands':
                  promotionName = `${promotionName}::${contentModel.name}`
                  allowTrigger = false
                  break;
                case 'modulePageDescription':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                default:
                  break;
              }
            }
          }
          let eventData = [
            {
              promotion_id: promotionId,
              promotion_name: promotionName,
              creative_name: creativeName,
              creative_slot: creativeSlot,
              location_id: locationId
            }
          ]
          let executed = false
          if(promotionId && promotionName && creativeName && creativeSlot && locationId && allowTrigger) {
            executed = viewContentPromotionEvent(eventData)
          }
          if(executed) {
            setSuccess(true);
          }
        } catch (error) {
          console.error('View Promotion execution failed:', error);
        }
      };
  
      const intervalId = setInterval(async () => {
        if (!success) {
          await sucessFunction();
        } else {
          clearInterval(intervalId);
        }
      }, 500);
  
      return () => clearInterval(intervalId);
    }
  }, [inViewport, success, router.asPath])

  return (
    <>
      {
        <div
          ref={ref}
          className={`product-list-wrap ${
            showProductMenu !== null
              ? showProductMenu
                ? 'productmenu-wrap'
                : 'productmenu-wrap-hidden pointer-events-none'
              : 'hide-productmenu-wrap'
          } absolute bottom-0 z-100 ${getProductPopUpPosition(heroType, 'productPopUpList')}`}
          onMouseLeave={() => {
            setShowProductMenu(false)
          }}
        >
          <div className={`flex flex-col justify-end`}>
            {products?.length > 0 &&
              products?.map((product, index) => (
                <div key={index+product.productId} className="">
                  <ProductPopUpCard product={product} moduleProps={moduleProps} productIndex={index+1}/>
                </div>
              ))}
            <div
              className={`flex flex-row bg-white px-3 py-18 ${getProductPopUpPosition(heroType)}`}
            >
              <div className={`${showProductMenu ? 'minus-icon-on-open' : 'minus-icon-on-close'}`}>
                <Icon
                  className={`cursor-pointer`}
                  viewBox={`0 0 12 12`}
                  size={12}
                  fill={'#000000'}
                  icon="minus"
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowProductMenu(false)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      }
      <style jsx>
        {`
          div.product-list-wrap {
            max-height: 500px;
            overflow-y: scroll;
            -ms-overflow-style: none; /* Internet Explorer 10+ */
            scrollbar-width: none; /* Firefox */
          }
          div.product-list-wrap::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }
          .minus-icon-on-open {
            animation: rotateMinusIcon 0.8s forwards;
          }

          .minus-icon-on-close {
            transform: rotateZ(180deg);
          }

          .hide-productmenu-wrap {
            visibility: hidden;
            z-index: -10;
          }

          .productmenu-wrap-hidden {
            animation: hideProductWrapper 0.4s forwards;
          }

          .py-18 {
            padding-top: 18px;
            padding-bottom: 18px;
          }
          @keyframes hideProductWrapper {
            0% {
              opacity: 1;
              z-index: 10;
            }
            100% {
              opacity: 0;
              z-index: -10;
            }
          }

          .productmenu-wrap {
            animation: showProductWrapper 0.6s forwards;
          }

          @keyframes showProductWrapper {
            0% {
              opacity: 0;
              z-index: -10;
            }
            100% {
              opacity: 1;
              z-index: 10;
            }
          }
          @keyframes rotateMinusIcon {
            0% {
              transform: rotateZ(90deg);
            }
            100% {
              transform: rotateZ(180deg);
            }
          }
          .w-180 {
            width: 180px;
          }
          .w-310 {
            width: 310px;
          }
          .bottom-21 {
            bottom: 21.5px;
          }
          .right-20 {
            right: 20px;
          }
          .left-20 {
            left: 20px;
          }
          @media (min-width: ${styleConfig.theme.screens.xl}) {
            .bottom-46 {
              bottom: 46px;
            }
            .right-65 {
              right: 65px;
            }
            .left-65 {
              left: 65px;
            }
          }
          @media (max-width: ${styleConfig.theme.screens.md}) {
            div.product-list-wrap {
              max-height: 400px;
            }
          }
        `}
      </style>
    </>
  )
}
export default ProductPopUp
